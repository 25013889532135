export const stagingConfig = {
  apiKey: 'AIzaSyAu2wabxDWH3GKOrmD5EpoX8SGRmyx-VPQ',
  authDomain: 'staging-taxnitro-ghl.firebaseapp.com',
  databaseURL: 'https://staging-taxnitro-ghl.firebaseio.com',
  projectId: 'staging-taxnitro-ghl',
  storageBucket: 'staging-taxnitro-ghl.appspot.com',
  messagingSenderId: '625753787718',
  appId: '1:625753787718:web:b2a4ec5868a8d85d6c6947',
}

export const productionConfig = {
  apiKey: 'AIzaSyDlGe68wVk9vs-I_bDxocYeh5NMwdKp-S8',
  authDomain: 'taxnitro-integrations.firebaseapp.com',
  databaseURL: 'https://taxnitro-integrations.firebaseio.com',
  projectId: 'taxnitro-integrations',
  storageBucket: 'taxnitro-integrations.appspot.com',
  messagingSenderId: '728344614218',
  appId: '1:728344614218:web:fcb77f0d4c8a23f65d1db3',
}

export type FirebaseConfig = {
  apiKey: string
  authDomain: string
  databaseURL: string
  projectId: string
  storageBucket: string
  messagingSenderId: string
  appId: string
  measurementId?: string
}
