<template>
  <transition
    enter-active-class="transition-transform ease-out duration-100"
    enter-from-class="transform -translate-y-24"
    enter-to-class="transform"
    leave-active-class="transition ease-in duration-75"
    leave-from-class="transform opacity-100 scale-100"
    leave-to-class="transform opacity-0 scale-95"
  >
    <div v-if="showMobileMenu" class="md:hidden origin-top">
      <div class="px-2 pt-2 pb-3 space-y-1 sm:px-3">
        <router-link
          :to="{ name: RouteName.DASHBOARD }"
          class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700"
          >Dashboard</router-link
        >

        <router-link
          :to="{ name: RouteName.ENROLMENT }"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Enrollment Requests</router-link
        >
        <router-link
          :to="{ name: RouteName.ENROLLED_COMPANIES }"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Enrolled Companies</router-link
        >

        <router-link
          v-if="isAdmin"
          :to="{ name: RouteName.MANAGE_USERS }"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Manage Users</router-link
        >
        <router-link
          v-if="isAdmin"
          :to="{ name: RouteName.MANAGE_CREDENTIALS }"
          class="block px-3 py-2 rounded-md text-base font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
          >Manage Credentials</router-link
        >
      </div>
      <div class="pt-4 pb-3 border-t border-gray-700">
        <div class="flex items-center px-5 space-x-3">
          <div class="flex-shrink-0">
            <img
              v-if="profilePicture"
              class="h-10 w-10 rounded-full"
              :src="profilePicture"
              alt=""
            />
            <div
              v-else
              class="h-10 w-10 rounded-full py-1 bg-brand text-white font-bold text-xl text-center"
            >
              {{ firstNameChar }}
            </div>
          </div>
          <div class="space-y-1">
            <div class="text-base font-medium leading-none text-white">
              {{ userName }}
            </div>
            <div class="text-sm font-medium leading-none text-gray-400">
              {{ userEmail }}
            </div>
          </div>
        </div>
        <div class="mt-3 px-2 space-y-1">
          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >Your Profile</a
          >

          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            >Settings</a
          >

          <a
            href="#"
            class="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
            @click="logout"
            >Sign out</a
          >
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { RouteName } from '../../router/RouteName'
import { defineComponent } from 'vue'
import { auth, userData } from '@/firebase'

export default defineComponent({
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      RouteName: RouteName,
    }
  },
  computed: {
    userName(): string {
      return userData?.name ?? 'Anonymous'
    },
    firstNameChar(): string {
      return this.userName.charAt(0)
    },
    userEmail(): string {
      return userData?.email ?? 'Anonymous'
    },
    profilePicture(): string | null {
      return userData?.profilePicture || null
    },
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
  },
  methods: {
    async logout() {
      try {
        await auth.signOut()
        this.$router.push(RouteName.LOGIN)
      } catch (error) {
        console.log(error)
      }
    },
  },
})
</script>

<style></style>
