
import { defineComponent } from 'vue'
import ProfileAvatar from './ProfileAvatar.vue'
import Links from './Links.vue'
import Logo from './Logo.vue'
import MobileMenu from './MobileMenu.vue'

export default defineComponent({
  components: {
    ProfileAvatar,
    Links,
    Logo,
    MobileMenu,
  },
  data() {
    return {
      showMobileMenu: false,
    }
  },
})
