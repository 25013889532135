
import { RouteName } from '../../router/RouteName'
import { defineComponent } from 'vue'
import { auth, userData } from '@/firebase'

export default defineComponent({
  props: {
    showMobileMenu: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      RouteName: RouteName,
    }
  },
  computed: {
    userName(): string {
      return userData?.name ?? 'Anonymous'
    },
    firstNameChar(): string {
      return this.userName.charAt(0)
    },
    userEmail(): string {
      return userData?.email ?? 'Anonymous'
    },
    profilePicture(): string | null {
      return userData?.profilePicture || null
    },
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
  },
  methods: {
    async logout() {
      try {
        await auth.signOut()
        this.$router.push(RouteName.LOGIN)
      } catch (error) {
        console.log(error)
      }
    },
  },
})
