const isDev = process.env.NODE_ENV !== 'production'
const isStaging = process.env.VUE_APP_IS_STAGING === 'true'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/functions'
import { CustomClaims } from 'types/custom-claim'
import { UserProfile } from 'types/user-profile'
if (!isDev) {
  import('firebase/analytics')
}

import {
  stagingConfig,
  productionConfig,
  FirebaseConfig,
} from '../config/firebaseConfig'
import { FirestoreColNames } from './enums/FirestoreColNames'

// Enum exports
export const PersistenceTypes = firebase.auth.Auth.Persistence

let firebaseConfig: FirebaseConfig
if (isDev || isStaging) {
  firebaseConfig = stagingConfig
} else {
  firebaseConfig = productionConfig
}

const fb = firebase.initializeApp(firebaseConfig)
// utils
const db = fb.firestore()
const auth = fb.auth()
const functions = fb.functions()
const FieldValue = firebase.firestore.FieldValue

// Handle user and token

let usersDocRefListener: (() => void) | null = null
let userData: null | UserProfile

function setForceTokenRefreshListener(user: null | firebase.User) {
  // Force refreshToken listener
  if (user && !usersDocRefListener) {
    const refreshTokenFlagCallback = () => {
      user.getIdToken(true)
    }
    usersDocRefListener = db
      .collection(FirestoreColNames.USERS)
      .doc(user.uid)
      .onSnapshot(refreshTokenFlagCallback)
  } else if (!user && usersDocRefListener) {
    // Unsubsribe after logout
    usersDocRefListener()
  }
}

async function setUser(user: null | firebase.User) {
  if (user) {
    const snap = await db
      .collection(FirestoreColNames.USERS)
      .doc(user.uid)
      .get()
    const data = snap.data() as Omit<UserProfile, 'claims'>

    const idTokenResult = await user.getIdTokenResult()
    const customClaims = idTokenResult.claims as CustomClaims
    userData = { ...data, claims: customClaims }
  } else {
    userData = null
  }
}

function login(email: string, password: string, rememberMe = false) {
  const persistence = rememberMe
    ? PersistenceTypes.LOCAL
    : PersistenceTypes.SESSION
  // Initiate firebase auth login
  return new Promise(resolve => {
    auth
      .setPersistence(persistence)
      .then(() => {
        return auth.signInWithEmailAndPassword(email, password)
      })
      .then(() => resolve('success'))
      .catch((err: firebase.auth.Error) => {
        if (err.code === 'auth/user-disabled') {
          resolve('user-disabled')
        } else {
          resolve('incorrect')
        }
      })
  })
}

export {
  fb,
  db,
  auth,
  functions,
  FieldValue,
  setUser,
  setForceTokenRefreshListener,
  userData,
  login,
}
