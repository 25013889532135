<template>
  <div class="hidden md:block">
    <div class="ml-10 flex items-baseline space-x-4">
      <router-link
        :to="{ name: RouteName.DASHBOARD }"
        class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        :class="{ 'bg-gray-700': currentRoute === RouteName.DASHBOARD }"
        >Dashboard</router-link
      >

      <router-link
        :to="{ name: RouteName.ENROLMENT }"
        class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        :class="{ 'bg-gray-700': currentRoute === RouteName.ENROLMENT }"
        >Enrollment Requests</router-link
      >

      <router-link
        :to="{ name: RouteName.ENROLLED_COMPANIES }"
        class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        :class="{
          'bg-gray-700': currentRoute === RouteName.ENROLLED_COMPANIES,
        }"
        >Enrolled Companies</router-link
      >

      <router-link
        v-if="isAdmin"
        :to="{ name: RouteName.MANAGE_USERS }"
        class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        :class="{ 'bg-gray-700': currentRoute === RouteName.MANAGE_USERS }"
        >Manage Users</router-link
      >
      <router-link
        v-if="isAdmin"
        :to="{ name: RouteName.MANAGE_CREDENTIALS }"
        class="px-3 py-2 rounded-md text-sm font-medium text-gray-300 hover:text-white hover:bg-gray-700 focus:outline-none focus:text-white focus:bg-gray-700"
        :class="{
          'bg-gray-700': currentRoute === RouteName.MANAGE_CREDENTIALS,
        }"
        >Manage Credentials</router-link
      >
    </div>
  </div>
</template>

<script lang="ts">
import { userData } from '@/firebase'
import { defineComponent } from 'vue'
import { RouteName } from '../../router/RouteName'

export default defineComponent({
  data() {
    return {
      RouteName,
    }
  },
  computed: {
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
    currentRoute() {
      return this.$route.name
    },
  },
})
</script>

<style></style>
