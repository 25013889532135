<template>
  <div class="flex-shrink-0">
    <img
      class="h-8 w-8 object-cover"
      src="@/assets/logos/taxnitro/taxnitro-logo-square.png"
      alt="TaxNitro logo"
    />
  </div>
  <div class="flex-shrink-0 ml-2 mt-1">
    <img
      class="h-8 w-8 object-cover"
      src="@/assets/logos/rightway/rightway-logo-square.png"
      alt="RightWayTax logo"
    />
  </div>
</template>

<script>
export default {}
</script>

<style></style>
