export enum RouteName {
  DASHBOARD = 'Dashboard',
  LOGIN = 'Login',
  REGISTER = 'Create Account',
  ENROLMENT = 'Enrollment Requests',
  ENROLMENT_REVIEW = 'Enrollment Review',
  ENROLLED_COMPANIES = 'Enrolled Companies',
  MANAGE_USERS = 'Manage Users',
  MANAGE_CREDENTIALS = 'Manage Credentials',
  EDIT_USER = 'Edit User',
}
