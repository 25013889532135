<template>
  <NavBar />
  <Header />
  <main>
    <router-view v-slot="{ Component }">
      <transition
        enter-active-class="transition-opacity duration-75"
        enter-from-class="opacity-25"
        enter-to-class="opacity-100"
        leave-active-class="transition-opacity duration-150"
        leave-from-class="opacity-100"
        leave-to-class="opacity-25"
      >
        <keep-alive>
          <component :is="Component" />
        </keep-alive>
      </transition>
    </router-view>
  </main>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import NavBar from '../components/navbar/NavBar.vue'
import Header from '../components/Header.vue'

export default defineComponent({
  components: {
    NavBar,
    Header,
  },
})
</script>

<style scoped></style>
