
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    routeName(): string {
      return this.$route.name?.toString() ?? 'Dashboard'
    },
  },
})
