export const enum FirestoreColNames {
  APP_CONFIG = 'appConfig',
  ENROLMENT_REQ_HISTORY = 'enrolmentReqHistory',
  ENROLMENT_REQUESTS = 'enrolmentRequests',
  USERS = 'users',
  ENROLLED_COMPANIES = 'enrolledCompanies',
  FAILURE_LOGS = 'failureLogs',
  LATEST_MAGENTRIX_DATA = 'latestMagentrixData',
  SYNC_LOGS = 'syncLogs',
}
