import { App as AppVueType, createApp } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'
// import { store } from './store'
import { auth, setForceTokenRefreshListener, setUser } from './firebase'

import './assets/styles/index.css'

// import { FontAwesomeIcon } from '@/plugins/font-awesome'

let app: AppVueType<Element> | null = null

// let refreshTokenFlagCallback: (() => void) | null = null
// let usersDocRef: (() => void) | null = null
auth.onAuthStateChanged(
  async user => {
    // Remove previous listener if exists
    // if (refreshTokenFlagCallback && usersDocRef) {
    //   usersDocRef()
    // }
    await setUser(user)

    /* 
      Unmount existing Vue app and remount for new user
      This is to ensure everything is reset (auth/userData) when a new user login or logout
    **/
    if (app) {
      app.unmount('$app')
      app = null
    }
    app = createApp(App)
    app.use(router)
    app.mount('#app')

    // If Vue app not yet initialized, create and mount, this to prevent vue app initializing before firebase
    // if (!app) {
    //   app = createApp(App)
    //   // app.use(store)
    //   app.use(router)
    //   // app.component('fa', FontAwesomeIcon)
    //   app.mount('#app')
    // }

    setForceTokenRefreshListener(user)

    // // Force refreshToken listener
    // if (user) {
    //   refreshTokenFlagCallback = () => {
    //     // Force refresh if there's already existing claims in app's store
    //     if (store.state.user) {
    //       user.getIdToken(true)
    //     }
    //   }
    //   usersDocRef = db
    //     .collection(FirestoreColNames.USERS)
    //     .doc(user.uid)
    //     .onSnapshot(refreshTokenFlagCallback)
    //   store.dispatch(ActionTypes.FETCH_USER_PROFILE, user)
    // } else {
    //   store.dispatch(ActionTypes.FETCH_USER_PROFILE, null)
    // }
  },
  err => {
    console.log(err)
  }
)
