
import { auth, userData } from '@/firebase'
import { defineComponent } from 'vue'
import { RouteName } from '../../router/RouteName'

export default defineComponent({
  name: 'ProfileAvatar',
  data() {
    return {
      showProfileDropdown: false,
    }
  },
  computed: {
    userName(): string {
      return userData?.name ?? 'Anonymous'
    },
    firstNameChar(): string {
      return this.userName.charAt(0)
    },
    userEmail(): string {
      return userData?.email ?? 'Anonymous'
    },
    profilePicture(): string | null {
      return userData?.profilePicture || null
    },
    role() {
      return userData?.claims?.role ?? 'Unauthorized'
    },
  },
  methods: {
    async logout() {
      await auth.signOut()
      this.$router.push(RouteName.LOGIN)
    },
  },
})
