<template>
  <header class="bg-white border-b">
    <div class="max-w-7xl mx-auto py-3 px-4 sm:px-6 lg:px-8">
      <h1 class="text-xl font-semibold leading-tight text-gray-700">
        {{ routeName }}
      </h1>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    routeName(): string {
      return this.$route.name?.toString() ?? 'Dashboard'
    },
  },
})
</script>

<style></style>
