import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
// import * as firebase from 'firebase/app'
// import 'firebase/auth'
import { auth } from '../firebase'

import { RouteName } from './RouteName'

import MainLayout from '../layouts/MainLayout.vue'
import { CustomClaims } from 'types/custom-claim'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: { name: RouteName.DASHBOARD },
  },
  {
    name: RouteName.LOGIN,
    path: '/login',
    component: () => import('../views/Login.vue'),
  },
  // {
  //   name: RouteName.REGISTER,
  //   path: '/register',
  //   component: () => import('../views/Register.vue'),
  // },
  {
    path: '/dashboard',
    component: MainLayout,
    children: [
      {
        name: RouteName.DASHBOARD,
        path: '',
        component: () => import('../views/Dashboard.vue'),
      },
    ],
  },
  {
    path: '/enrolment',
    component: MainLayout,
    children: [
      {
        name: RouteName.ENROLMENT,
        path: '',
        component: () => import('../views/EnrolmentRequests.vue'),
      },
      {
        name: RouteName.ENROLMENT_REVIEW,
        path: ':id',
        component: () =>
          import('../components/enrolment/review/EnrolmentReview.vue'),
      },
    ],
  },
  {
    path: '/enrolled-companies',
    component: MainLayout,
    children: [
      {
        name: RouteName.ENROLLED_COMPANIES,
        path: '',
        component: () => import('../views/EnrolledCompanies.vue'),
      },
    ],
  },
  {
    path: '/manage-users',
    component: MainLayout,
    beforeEnter: async () => {
      if (!auth.currentUser) return false
      const tokenResult = await auth.currentUser.getIdTokenResult()

      const claims = tokenResult.claims as CustomClaims
      const isAdmin = claims.role === 'admin'
      if (!isAdmin) return false
      return true
    },
    children: [
      {
        name: RouteName.MANAGE_USERS,
        path: '',
        component: () => import('../views/ManageUsers.vue'),
      },
    ],
  },
  {
    path: '/manage-credentials',
    component: MainLayout,
    beforeEnter: async () => {
      if (!auth.currentUser) return false
      const tokenResult = await auth.currentUser.getIdTokenResult()

      const claims = tokenResult.claims as CustomClaims
      const isAdmin = claims.role === 'admin'
      if (!isAdmin) return false
      return true
    },
    children: [
      {
        name: RouteName.MANAGE_CREDENTIALS,
        path: '',
        component: () => import('../views/ManageCredentials.vue'),
      },
    ],
  },
]

const history = createWebHistory()
const router = createRouter({
  history,
  routes,
})

router.beforeEach(to => {
  const isAuthenticated = auth.currentUser
  if (
    to.name !== RouteName.LOGIN &&
    to.name !== RouteName.REGISTER &&
    !isAuthenticated
  )
    return { name: RouteName.LOGIN }
  else if (
    (to.name === RouteName.LOGIN || to.name === RouteName.REGISTER) &&
    isAuthenticated
  )
    return { name: RouteName.DASHBOARD }
  else return
})

export default router
