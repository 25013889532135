
import { userData } from '@/firebase'
import { defineComponent } from 'vue'
import { RouteName } from '../../router/RouteName'

export default defineComponent({
  data() {
    return {
      RouteName,
    }
  },
  computed: {
    isAdmin(): boolean {
      return userData?.claims?.role === 'admin' ?? false
    },
    currentRoute() {
      return this.$route.name
    },
  },
})
