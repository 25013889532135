
import { defineComponent } from 'vue'
import NavBar from '../components/navbar/NavBar.vue'
import Header from '../components/Header.vue'

export default defineComponent({
  components: {
    NavBar,
    Header,
  },
})
