<template>
  <div class="ml-3 relative">
    <div>
      <button
        class="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
        id="user-menu"
        aria-label="User menu"
        aria-haspopup="true"
        @click="showProfileDropdown = !showProfileDropdown"
        @blur="showProfileDropdown = false"
      >
        <img
          v-if="profilePicture"
          class="h-10 w-10 rounded-full"
          :src="profilePicture"
          alt=""
        />
        <div
          v-else
          class="h-10 w-10 rounded-full py-1 bg-brand text-white font-bold text-xl text-center"
        >
          {{ firstNameChar }}
        </div>
      </button>
    </div>

    <transition
      enter-active-class="transition ease-out duration-100"
      enter-from-class="transform opacity-0 scale-95"
      enter-to-class="transform opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75"
      leave-from-class="transform opacity-100 scale-100"
      leave-to-class="transform opacity-0 scale-95"
    >
      <div
        v-if="showProfileDropdown"
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg z-10"
      >
        <div
          class="py-1 rounded-md bg-white shadow-xs"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="user-menu"
        >
          <div
            class="block px-4 py-4 text-sm text-gray-700 bg-brand-light -mt-1 rounded-t"
          >
            <p class="font-bold text-xs">{{ userEmail }}</p>
            <p class="italic text-xs">{{ role }}</p>
          </div>
          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300"
            role="menuitem"
            >Your Profile</a
          >

          <a
            href="#"
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300"
            role="menuitem"
            >Settings</a
          >

          <a
            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-300 cursor-pointer"
            role="menuitem"
            @click="logout"
            >Sign out</a
          >
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { auth, userData } from '@/firebase'
import { defineComponent } from 'vue'
import { RouteName } from '../../router/RouteName'

export default defineComponent({
  name: 'ProfileAvatar',
  data() {
    return {
      showProfileDropdown: false,
    }
  },
  computed: {
    userName(): string {
      return userData?.name ?? 'Anonymous'
    },
    firstNameChar(): string {
      return this.userName.charAt(0)
    },
    userEmail(): string {
      return userData?.email ?? 'Anonymous'
    },
    profilePicture(): string | null {
      return userData?.profilePicture || null
    },
    role() {
      return userData?.claims?.role ?? 'Unauthorized'
    },
  },
  methods: {
    async logout() {
      await auth.signOut()
      this.$router.push(RouteName.LOGIN)
    },
  },
})
</script>

<style></style>
